vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Hidroxiapatita</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="hidroxiapatita de calcio" src="../../assets/medicinaEsteticaHidroxiapatitaDeCalcio.jpg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            Este relleno te ayudará con la eliminación de arrugas y dar volumen a partes de tu rostro que
                            han ido perdiendo elasticidad, mejorando de este modo la flacidez de la piel.
                        </p>
                        <p>
                            La <span class="p-text-bold">hidroxiapatita cálcica</span> es un producto de relleno que se
                            inyecta con una aguja fina o con cánula. Se anestesia previamente la zona a tratar para evitar
                            posibles molestias al paciente durante el tratamiento. La duración oscila entre los 12 y 18
                            meses, pero los resultados comienzan a ser más visibles a partir del mes, siendo a los seis
                            meses cuando estos resultados son más reseñables.
                        </p>
                        <p>
                            Este tratamiento es ambulatorio, mínimamente invasivo, no es doloroso y no requiere cirugía. Es
                            decir, tras finalizar el tratamiento, el paciente puede llevar a cabo sus hábitos diarios. La
                            única complicación es que pueden aparecer hematomas y algo de inflamación que desaparecerá
                            tomando antiinflamatorios durante los dos o tres días posteriores. Estos síntomas se reabsorben
                            totalmente con el paso del tiempo, con resultados duraderos de hasta 18 meses.
                        </p>
                       
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'HidroxiapatitaVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>